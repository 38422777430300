import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Talk } from '../datastore/DataStoreInterface';

import './FavouriteIcon.css';

interface FavouriteIconProps {
    talk: Talk;
    favourite: boolean,
    toggleFavourite: Function,
    speakerDetail?: boolean,
}

export default function FavouriteIcon(props: FavouriteIconProps) {
    let faIcon;
    if (props.favourite) {
        faIcon = <FontAwesomeIcon icon={solid('star')} size="2x" onClick={() => props.toggleFavourite(props.talk.id)} />
    } else {
        faIcon = <FontAwesomeIcon icon={regular('star')} size="2x" onClick={() => props.toggleFavourite(props.talk.id)} />
    }

    let titleText = "Add to favourites";
    if (props.favourite) {
        titleText = "Remove from favourites";
    }

    return <span className='talk-favourite-icon' title={titleText}>{faIcon}</span>;
}