import * as React from 'react';

import './DateSelect.css';

interface DateSelectProps {
    setDate: Function,
}

interface DateSelectState {
    activeIndex: number,
}

export default class DateSelect extends React.PureComponent<DateSelectProps, DateSelectState> {
    constructor(props: any) {
        super(props);
        this.state = {activeIndex: 0};
    }

    handleClick(date: Date, index: number) {
        this.setState({activeIndex: index});
        this.props.setDate(date);
    }

    render() {
        return (
            <div className="date-select fs-5 my-4">
                <ul className="nav justify-content-center">
                    <li className="nav-item">
                        <a className={`nav-link ${this.state.activeIndex === 0 ? 'active' : ''}`} href="#" onClick={() => this.handleClick(new Date(Date.UTC(2022, 9, 26)) , 0)}>26 October</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${this.state.activeIndex === 1 ? 'active' : ''}`} href="#" onClick={() => this.handleClick(new Date(Date.UTC(2022, 9, 27)), 1)}>27 October</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${this.state.activeIndex === 2 ? 'active' : ''}`} href="#" onClick={() => this.handleClick(new Date(Date.UTC(2022, 9, 28)), 2)}>28 October</a>
                    </li>
                </ul>
            </div>
        );
    }
}