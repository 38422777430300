import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import './ShowFavourites.css';

export default function ShowFavourites() {
    const location = useLocation();
    const slug = location.pathname;

    if (slug === "/favourites") {
        return (
            <div className="favourite-select row fs-5">
                <div className="col-xs-12">
                    <div className="input-group mb-3">
                        <Link to="/">
                            <div className="row align-items-center">
                                <div className="favourite-text col-xs-12 d-flex align-items-center">
                                    <FontAwesomeIcon icon={solid('star')} size="2x" className="favourite-icon me-3" />
                                    Show all sessions
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="favourite-select row fs-5">
                <div className="col-xs-12">
                    <div className="input-group mb-3">
                        <Link to="/favourites">
                            <div className="row align-items-center">
                                <div className="favourite-text col-xs-12 d-flex align-items-center">
                                    <FontAwesomeIcon icon={regular('star')} size="2x" className="favourite-icon me-3" />
                                    Show favourite sessions
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}