import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from './components/home/Home';
import TalkDetail from './components/talkdetail/TalkDetail';
import SpeakerDetail from './components/speakerdetail/SpeakerDetail';

import FakeDataStore from './datastore/FakeDataStore';
import APIDataStore from './datastore/APIDataStore';

import './index.css';
import { DataStoreInterface } from './datastore/DataStoreInterface';
import { localStorageAvailable } from './util';

interface IndexState {
    selectedTimeZone: {value: string, label: string},
    defaultTimeZone: string,
    favouriteTalks: number[],
    datastore: DataStoreInterface,
}

class App extends React.Component<{}, IndexState> {
    constructor(props: any) {
        super(props);
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let favourites = localStorageAvailable() ? localStorage.getItem('favourites') : false;
        this.state = {
            selectedTimeZone: {
                value: timeZone,
                label: timeZone.replaceAll('_', ' '),
            },
            defaultTimeZone: timeZone,
            favouriteTalks: favourites ? JSON.parse(favourites) : [],
            datastore: new APIDataStore(),
            //datastore: new FakeDataStore(),
        };
    }

    setTimeZone(selectedTimeZone: {value: string, label: string}) {
        this.setState({selectedTimeZone: selectedTimeZone});
    }

    toggleFavourite(talkId: number) {
        let favouriteTalks: number[] = this.state.favouriteTalks;
        if (this.state.favouriteTalks.indexOf(talkId) === -1) {
            favouriteTalks.push(talkId);
        } else {
            favouriteTalks = favouriteTalks.filter(talk => talk !== talkId);
        }
        this.setState({favouriteTalks: favouriteTalks})
        if (localStorageAvailable()) {
            localStorage.setItem('favourites', JSON.stringify(favouriteTalks));
        }
    }

    render() {
        return (
            <React.StrictMode>
                <Router>
                    <Routes>
                        <Route path="/" element={<Home datastore={this.state.datastore} selectedTimeZone={this.state.selectedTimeZone}
                                                    setTimeZone={this.setTimeZone.bind(this)} favouriteTalks={this.state.favouriteTalks}
                                                    toggleFavourite={this.toggleFavourite.bind(this)} showFavourites={false} />} />
                        <Route path="/talk/:talkId" element={<TalkDetail datastore={this.state.datastore} toggleFavourite={this.toggleFavourite.bind(this)}
                                                                favouriteTalks={this.state.favouriteTalks}
                                                                selectedTimeZone={this.state.selectedTimeZone} />} />
                        <Route path="/speaker/:speakerId" element={<SpeakerDetail datastore={this.state.datastore}
                                                                favouriteTalks={this.state.favouriteTalks}
                                                                toggleFavourite={this.toggleFavourite.bind(this)}
                                                                selectedTimeZone={this.state.selectedTimeZone} />} />
                        <Route path="/favourites" element={<Home datastore={this.state.datastore}
                                                    selectedTimeZone={this.state.selectedTimeZone}
                                                    setTimeZone={this.setTimeZone.bind(this)}
                                                    favouriteTalks={this.state.favouriteTalks} showFavourites={true}
                                                    toggleFavourite={this.toggleFavourite.bind(this)} />} />
                    </Routes>
                </Router>
            </React.StrictMode>
        );
    }
};

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(<App />);