import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Select from 'react-select';

import { Track } from '../datastore/DataStoreInterface';

import './TrackList.css';

interface TrackListProps {
    tracks: () => Promise<Track[]>,
    setSelectedTracks: Function,
    selectedTracks: [{label: string, value: number}] | [],
}

interface TrackListState {
    tracks: Track[];
}

export default class TrackList extends React.PureComponent<TrackListProps, TrackListState> {
    constructor(props: TrackListProps) {
        super(props);
        this.state = {tracks: []};
    }

    async componentDidMount() {
        if (this.state.tracks.length === 0) {
            this.setState({tracks: await this.props.tracks()});
        }
    }

    render() {
        return (
            <div className="track-list row fs-5">
                <div className="col-xs-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text"><FontAwesomeIcon icon={solid('shuffle')} /></span>
                        <Select className="form-control form-control-lg p-0 ps-3" classNamePrefix="select" isMulti={true} 
                            onChange={newValue => this.props.setSelectedTracks(newValue)} 
                            placeholder="Select tracks..."
                            value={this.props.selectedTracks}
                            options={this.state.tracks.map(track => {
                            return {label: track.attributes.name, value: track.id};
                        })} />
                    </div>
                </div>
            </div>
        );
    }
}