import { Talk } from '../../datastore/DataStoreInterface';

import './TalkLabel.css';

interface TalkLabelProps {
    talk: Talk,
}

export default function TalkLabel(props: TalkLabelProps) {
    return (
        <div className={`talk-label fs-6 btn btn-light`}>
            {props.talk.attributes.type}
        </div>
    );
}