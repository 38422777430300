import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import './HomeButton.css';

export default function HomeButton() {
    const navigate = useNavigate();

    return (
        <div className="home-btn fs-5 mb-3">
            <ul className="nav justify-content-left">
                <li className="nav-item btn btn-light">
                    <a href="/" onClick={(e) => { e.preventDefault(); navigate(-1) }}>
                        <span className="me-1"><FontAwesomeIcon icon={solid('arrow-left')} /></span>
                        Back
                    </a>
                </li>
            </ul>
        </div>
    );
}