import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import './SearchBar.css';

interface SearchBarProps {
    setSearchQuery: Function
}

export default class SearchBar extends React.PureComponent<SearchBarProps, {}> {
    render() {
        return (
            <div className="search-bar row fs-5">
                <div className="col-xs-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text"><FontAwesomeIcon icon={solid('magnifying-glass')} /></span>
                        <input className="form-control form-control-lg" type="text" placeholder="Search..." aria-label="Search" 
                            onChange={event => this.props.setSearchQuery((event.target as HTMLInputElement).value)} />
                    </div>
                </div>
            </div>
        );
    }
}