import { createEvent } from 'ics';
import { DateArray, EventStatus } from 'ics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Talk } from '../../datastore/DataStoreInterface';

import './AddToCalendar.css';

interface AddToCalendarProps {
    talk: Talk;
}

const downloadICS = (talk: Talk) => {
    let startDate = new Date(talk.attributes.start);
    let endDate = new Date(talk.attributes.end);
    const event = {
        start: [startDate.getUTCFullYear(), startDate.getUTCMonth()+1, startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes()] as DateArray,
        startInputType: 'utc' as ('utc' | 'local' | undefined),
        end: [endDate.getUTCFullYear(), endDate.getUTCMonth()+1, endDate.getUTCDate(), endDate.getUTCHours(), endDate.getUTCMinutes()] as DateArray,
        endInputType: 'utc' as ('utc' | 'local' | undefined),
        title: talk.attributes.title,
        description: talk.attributes.description,
        location: ('WEDF2022 ' + talk.relationships?.stream.attributes.name) || 'WEDF2022',
        url: 'https://forum.worldethicaldata.org/',
        status: "CONFIRMED" as EventStatus,
        busyStatus: 'BUSY' as ('TENTATIVE' | 'BUSY' | 'FREE' | 'OOF' | undefined),
        organizer: { name: 'World Ethical Data Forum 2022', email: 'contact@worldethicaldata.org' },
    };

    createEvent(event, (err, val) => {
        if (err) {
            console.error(err);
            return;
        }
        const element = document.createElement('a');
        const file = new Blob([val], {type: 'text/calendar'});
        element.href = URL.createObjectURL(file);
        element.download = "event.ics";
        document.body.appendChild(element); // firefox needs this
        element.click();
        return;
    });
}

export default function AddToCalendarIcon(props: AddToCalendarProps) {
    return (
        <span className='talk-ics-icon' title='Add to calendar'>
            <FontAwesomeIcon icon={solid('calendar-plus')} size="2x" onClick={() => downloadICS(props.talk)} />
        </span>
    );
}