import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import { DataStoreInterface, Speaker, Talk } from '../../datastore/DataStoreInterface';

import HomeButton from '../HomeButton';
import FavouriteIcon from '../FavouriteIcon';
import AddToCalendarIcon from '../talk/AddToCalendarIcon';

import './SpeakerDetail.css';

interface SpeakerDetailProps {
    datastore: DataStoreInterface,
    selectedTimeZone: {value: string, label: string},
    toggleFavourite: Function,
    favouriteTalks: number[],
}

export default function SpeakerDetail(props: SpeakerDetailProps) {
    const { speakerId } = useParams();
    const [speaker, setSpeaker] = useState({} as Speaker);
    const [loading, setLoading] = useState(true);

    useEffect(() => {    
        let speakerIdNum = 0;
        if (speakerId) {
            speakerIdNum = parseInt(speakerId);
        }
        props.datastore.fetchSpeaker(speakerIdNum).then(data => {
            if (data.length === 1) {
                setSpeaker(data[0]);
                setLoading(false);
            }
        });
    }, [props.datastore, speakerId]);

    return (
        <div className="container mw-100">
            <HomeButton />
            {loading ? (
                <div className="row loader-container text-center align-items-center">
                    <div className='col-xs-12 col-md-12 text-center'>
                        <ClipLoader color={'#05ffcf'} loading={loading} size={150} />
                    </div>
                </div>
            ): (
                <div className="speaker-detail" key={speaker.id}>
                    <div className='row'>
                        <div className="col-md-3 col-xs-6" key={speaker.id}>
                                <img src={speaker.attributes.photo?.url} alt={speaker.attributes.photo?.alt}
                                    title={speaker.attributes.photo?.title} className="card-img-top" />
                        </div>
                        <div className='col-md-9 col-xs-6'>
                            <h1 className='speaker-name'>{speaker.attributes.name}</h1>
                            <h3 className='speaker-title-affil'>{speaker.attributes.title} - {speaker.attributes.affiliation}</h3>
                            <p className='speaker-bio'>{speaker.attributes.bio}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="speaker-details col-md-12 col-xs-12">
                            <div className='speaker-talks'>
                                <h2>Sessions</h2>
                                {speaker.relationships?.talks.map((talk: Talk) => (
                                    <div className='talk row align-items-top' key={talk.id}>
                                        <div className="talk-times mt-2 col-xs-12 col-md-2 fs-6">
                                        <span className='date'>{new Date(talk.attributes.start).toLocaleDateString(undefined, {
                                            year: 'numeric', month: 'long', day: 'numeric', timeZone: props.selectedTimeZone.value,
                                        })}</span>
                                        <span className="start-time">{new Date(talk.attributes.start).toLocaleTimeString(undefined, {
                                            timeStyle: "short", timeZone: props.selectedTimeZone.value})}</span>{' - '}
                                        <span className="end-time">{new Date(talk.attributes.end).toLocaleTimeString(undefined, {
                                            timeStyle: "short", timeZone: props.selectedTimeZone.value})}</span>
                                        <span className="timezone">Time zone: {props.selectedTimeZone.label}</span>
                                        </div>
                                        <div className='col-xs-12 col-md-10 details'>
                                            <div className="row align-items-center">
                                                <div className="col-sm-10 col-md-10">
                                                    <h3 className='talk-title'><Link to={`/talk/${talk.id}`}>{talk.attributes.title}</Link></h3>
                                                </div>
                                                <div className='col-sm-2 col-md-2 text-end'>
                                                    <AddToCalendarIcon talk={talk} />
                                                    <FavouriteIcon talk={talk} favourite={props.favouriteTalks.indexOf(talk.id) !== -1}
                                                        toggleFavourite={props.toggleFavourite} speakerDetail={true} />
                                                </div>
                                            </div>
                                            
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-12'>
                                                    <p>{talk.attributes.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

    );
}