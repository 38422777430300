import { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { DataStoreInterface, Talk } from '../../datastore/DataStoreInterface';
import TalkComponent from '../talk/Talk';

import './TalksList.css';

interface TalksListProps {
    datastore: DataStoreInterface,
    daySelected: Date,
    searchQuery: string,
    filterSpeakers: [{value: number, label: string}] | [],
    filterTracks: [{value: number, label: string}] | [],
    setSelectedTracks: Function,
    selectedTimeZone: {value: string, label: string},
    favouriteTalks: number[],
    toggleFavourite: Function,
    showFavourites: boolean,
}

export default function TalksList(props: TalksListProps) {
    let emptyTalkList: Talk[] = [];
    const [talks, setTalks] = useState(emptyTalkList);
    const [fetchedData, setFetchedData] = useState(emptyTalkList);
    const [fetchedAllData, setFetchedAllData] = useState(emptyTalkList);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.datastore.fetchTalks().then(allTalks => { setFetchedAllData(allTalks); });
    }, [props.datastore]);

    useEffect(() => {
        if (props.showFavourites) {
            // TODO API call that only fetches favourite talks by ID
            props.datastore.fetchFavouriteTalks(props.favouriteTalks, props.daySelected).then(data => {
                setFetchedData(data);
                setLoading(false);
                setTalks(data);
            });
        } else {
            props.datastore.fetchTalksByDate(props.daySelected).then(data => {
                setFetchedData(data);
                setLoading(false);
                setTalks(data);
            });
        }
    }, [props.showFavourites, props.favouriteTalks, props.daySelected, props.datastore]);

    useEffect(() => {
        if (props.searchQuery !== "" && props.searchQuery.length >= 3) {
            props.datastore.filterTalksBySearch(fetchedAllData, props.searchQuery).then(data => setTalks(data));
        } else {
            setTalks(fetchedData);
        }
    }, [props.searchQuery, props.showFavourites, props.daySelected, fetchedData, fetchedAllData, props.datastore]);

    useEffect(() => {
        if (props.filterSpeakers.length > 0) {
            props.datastore.filterTalksBySpeakers(fetchedAllData, props.filterSpeakers).then(data => setTalks(data));
        } else {}
            setTalks(fetchedData);
    }, [props.filterSpeakers, fetchedData, fetchedAllData, props.datastore]);

    useEffect(() => {
        if (props.filterTracks.length > 0) {
            props.datastore.filterTalksByTracks(fetchedAllData, props.filterTracks).then(data => setTalks(data));
        } else {
            setTalks(fetchedData);
        }
    }, [props.filterTracks, fetchedData, fetchedAllData, props.datastore]);

    return (
        <div className="container">
            {loading ? (
                <div className="row loader-container text-center align-items-center">
                    <div className='col-xs-12 col-md-12 text-center'>
                        <ClipLoader color={'#05ffcf'} loading={loading} size={150} />
                    </div>
                </div>
            ): (
            <div className='talks row'>
                <div className='col-xs-12 col-md-6 my-4 stream'>
                    <h3 className='text-center'>Stream 1</h3>
                    {talks.filter(talk => talk.relationships?.stream.id === 1)
                            .map((talk) => {
                            if (props.favouriteTalks.indexOf(talk.id) !== -1) {
                                return <TalkComponent talk={talk} key={talk.id} setSelectedTracks={props.setSelectedTracks}
                                    selectedTimeZone={props.selectedTimeZone} favourite={true} toggleFavourite={props.toggleFavourite} />
                            } else {
                                return <TalkComponent talk={talk} key={talk.id} setSelectedTracks={props.setSelectedTracks}
                                    selectedTimeZone={props.selectedTimeZone} favourite={false} toggleFavourite={props.toggleFavourite} />
                            }
                    })}
                </div>
                <div className='col-xs-12 col-md-6 my-4 stream'>
                    <h3 className='text-center'>Stream 2</h3>
                    {talks.filter(talk => talk.relationships?.stream.id === 2)
                            .map((talk) => {
                            if (props.favouriteTalks.indexOf(talk.id) !== -1) {
                                return <TalkComponent talk={talk} key={talk.id} setSelectedTracks={props.setSelectedTracks}
                                    selectedTimeZone={props.selectedTimeZone} favourite={true} toggleFavourite={props.toggleFavourite} />
                            } else {
                                return <TalkComponent talk={talk} key={talk.id} setSelectedTracks={props.setSelectedTracks}
                                    selectedTimeZone={props.selectedTimeZone} favourite={false} toggleFavourite={props.toggleFavourite} />
                            }
                    })}
                </div>
            </div>
            )}
        </div>
    );
}