export interface Photo {
    url: string,
    alt: string,
    title: string,
}

export interface Speaker {
    id: number,
    attributes: {
        name: string,
        bio: string,
        title: string,
        affiliation: string,
        matrix_id: string | null,
        email: string | null,
        photo: Photo | null
    },
    relationships: {
        talks: Talk[],
    } | null
}

export interface Track {
    id: number,
    attributes: {
        name: string
    }
}

export interface Talk {
    id: number,
    attributes: {
        type: string,
        title: string,
        description: string,
        start: Date,
        end: Date,
        thumbnail: Photo | null,
        video_url: string | null,
    },
    relationships: {
        stream: {
            id: number,
            attributes: {
                name: string
            }
        },
        speakers: Speaker[],
        tracks: Track[],
    } | null,
}

export interface DataStoreData {
    talks: Talk[],
    speakers: Speaker[],
    tracks: Track[]
};

export abstract class DataStoreInterface {
    abstract fetchTalks(): Promise<Talk[]>;
    abstract fetchTalksByDate(date: Date): Promise<Talk[]>;
    abstract fetchFavouriteTalks(talkIds: number[], date?: Date): Promise<Talk[]>;
    abstract filterTalksBySearch(talks: Talk[], searchQuery: string): Promise<Talk[]>;
    abstract filterTalksByDate(talks: Talk[], date: Date): Promise<Talk[]>;
    abstract filterTalksBySpeakers(talks: Talk[], speakers: [{value: number, label: string}] | []): Promise<Talk[]>;
    abstract filterTalksByTracks(talks: Talk[], tracks: [{value: number, label: string}] | []): Promise<Talk[]>;
    abstract fetchSpeakers(): Promise<Speaker[]>;
    abstract fetchSpeaker(speakerId: number): Promise<Speaker[]>;
    abstract fetchTracks(): Promise<Track[]>;
    abstract fetchTalk(talkId: number): Promise<Talk[]>;
}