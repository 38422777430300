import * as React from 'react';

import DateSelect from '../DateSelect';

interface HeaderProps {
    setDate: Function,
}

export default function Header(props: HeaderProps) {
    return (
        <div className="header">
            <DateSelect setDate={props.setDate} />
        </div>
    );
}