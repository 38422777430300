import * as DSI from './DataStoreInterface';

export default class APIDataStore extends DSI.DataStoreInterface {
    static api_prefix: string = 'https://agenda-api.worldethicaldataforum.org';
    //static api_prefix: string = 'http://localhost:8888';

    private compareTalks(a: DSI.Talk, b: DSI.Talk): number {
        return a.attributes.start < b.attributes.start ? -1 : 1;
    }

    private compareName(a: any, b: any): number {
        return a.attributes.name < b.attributes.name ? -1 : 1;
    }

    private onlyUnique(value: any, index: any, self: any) { 
        return self.indexOf(value) === index;
    }

    public async fetchTalks(): Promise<DSI.Talk[]> {
        return fetch(APIDataStore.api_prefix + '/v1/talks', {method: 'GET', headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }}).then((result: any) => {
            if (result.status === 200) {
                return result.json();
            }
            return result;
        }).then((result: any) => {
            if ('talks' in result) {
                return result['talks'].sort(this.compareTalks);
            }
            return [];
        }).catch((err) => console.error(err));
    }

    public async fetchTalksByDate(date: Date): Promise<DSI.Talk[]> {
        return fetch(APIDataStore.api_prefix + `/v1/talks/by-date/${date.toISOString().substring(0, 10)}`, {method: 'GET', headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }}).then((result: any) => {
            if (result.status === 200) {
                return result.json();
            }
            return result;
        }).then((result: any) => {
            if ('talks' in result) {
                return result['talks'].sort(this.compareTalks);
            }
            return [];
        }).catch((err) => console.error(err));
    }

    public async fetchFavouriteTalks(talkIds: number[], date?: Date): Promise<DSI.Talk[]> {
        if (date) {
            return (await this.fetchTalksByDate(date)).filter(talk => talkIds.indexOf(talk.id) !== -1);
        }
        return (await this.fetchTalks()).filter(talk => talkIds.indexOf(talk.id) !== -1);
    }

    public async filterTalksBySearch(talks: DSI.Talk[], searchQuery: string): Promise<DSI.Talk[]> {
        return talks.filter(talk => {
            if (talk.attributes && talk.attributes.title !== null && talk.attributes.description !== null) {
                return (talk.attributes.title.toLowerCase().includes(searchQuery.toLowerCase())
                    || talk.attributes.description.toLowerCase().includes(searchQuery.toLowerCase()));
            }
            return false;
            }).sort(this.compareTalks);
    }

    public async filterTalksByDate(talks: DSI.Talk[], date: Date): Promise<DSI.Talk[]> {
        return talks.filter(talk => new Date(talk.attributes.start).toDateString() === date.toDateString())
            .sort(this.compareTalks);
    }

    public async filterTalksBySpeakers(talks: DSI.Talk[], speakers: [{value: number, label: string}] | []): Promise<DSI.Talk[]> {
        let selectedSpeakerIds = speakers.map(speaker => speaker.value);
        let returned_talks = [];
        for (let talk of talks) {
            if (talk.relationships?.speakers) {
                for (let speaker of talk.relationships?.speakers) {
                    if (selectedSpeakerIds.indexOf(speaker.id) !== -1) {
                        returned_talks.push(talk)
                    }
                }
            }
        }

        return returned_talks.filter(this.onlyUnique).sort(this.compareTalks);
    }

    public async filterTalksByTracks(talks: DSI.Talk[], tracks: [{value: number, label: string}] | []): Promise<DSI.Talk[]> {
        let selectedTrackIds = tracks.map(track => track.value);
        let returned_talks = [];
        for (let talk of talks) {
            if (talk.relationships?.tracks) {
                for (let track of talk.relationships?.tracks) {
                    if (selectedTrackIds.indexOf(track.id) !== -1) {
                        if (returned_talks.indexOf(talk) === -1) {
                            returned_talks.push(talk)
                        }
                    }
                }
            }
        }
        return returned_talks.filter(this.onlyUnique).sort(this.compareTalks);
    }

    public async fetchTalk(talkId: number): Promise<DSI.Talk[]> {
        return (await this.fetchTalks()).filter(talk => talk.id === talkId);
    }

    public async fetchSpeaker(speakerId: number): Promise<DSI.Speaker[]> {
        return (await this.fetchSpeakers()).filter(speaker => speaker.id === speakerId);
    }

    public async fetchSpeakers(): Promise<DSI.Speaker[]> {
        return fetch(APIDataStore.api_prefix + '/v1/speakers', {method: 'GET', headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }}).then((result: any) => {
            if (result.status === 200) {
                return result.json();
            }
            return result;
        }).then((result: any) => {
            if ('speakers' in result) {
                return result['speakers'].sort((a: any, b: any) =>  a.attributes.name < b.attributes.name ? -1 : 1);
            }
            return [];
        }).catch((err) => console.error(err));
    }

    public async fetchTracks(): Promise<DSI.Track[]> {
        return fetch(APIDataStore.api_prefix + '/v1/tracks', {method: 'GET', headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }}).then((result: any) => {
            if (result.status === 200) {
                return result.json()
            }
            return result;
        }).then((result: any) => {
            if ('tracks' in result) {
                return result['tracks'].sort((a: any, b: any) =>  a.attributes.name < b.attributes.name ? -1 : 1);
            }
            return [];
        }).catch((err) => console.error(err));
    }
}