import * as React from 'react';

import Header from '../header/Header';
import TalksList from '../talkslist/TalksList';
import SearchBar from '../SearchBar';
import SpeakerList from '../SpeakerList';
import TrackList from '../TrackList';
import TimeZoneList from '../TimeZoneList';
import ShowFavourites from '../ShowFavourites';
import ImportCalendar from '../ImportCalendar';

import { DataStoreInterface } from '../../datastore/DataStoreInterface';

import './Home.css';

interface HomeProps {
    datastore: DataStoreInterface,
    selectedTimeZone: {value: string, label: string},
    setTimeZone: Function,
    favouriteTalks: number[],
    toggleFavourite: Function,
    showFavourites: boolean,
}

interface HomeState {
    daySelected: Date,
    searchQuery: string,
    selectedSpeakers: [{value: number, label: string}] | [],
    selectedTracks: [{value: number, label: string}] | [],
}

export default class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: any) {
        super(props);
        this.state = {daySelected: new Date(Date.UTC(2022, 9, 26)), searchQuery: '', selectedSpeakers: [], selectedTracks: []};
    }

    setDate(date: Date) {
        this.setState({daySelected: date});
    }

    setSearchQuery(query: string) {
        this.setState({searchQuery: query});
    }

    setSelectedSpeakers(selectedSpeakers: [{value: number, label: string}]) {
        this.setState({selectedSpeakers: selectedSpeakers});
    }

    setSelectedTracks(selectedTracks: [{value: number, label: string}]) {
        this.setState({selectedTracks: selectedTracks});
    }

    render() {
        return (
            <div className="container mw-100">
                <Header setDate={this.setDate.bind(this)} />
                <div className="row">
                    <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3 col-xxl-2 my-4 filters-bar">
                        <h3 className="mb-4">Filters</h3>
                        <ShowFavourites />
                        <ImportCalendar favouriteTalks={this.props.favouriteTalks}
                            datastore={this.props.datastore} />
                        <SearchBar setSearchQuery={this.setSearchQuery.bind(this)} />
                        <SpeakerList speakers={this.props.datastore.fetchSpeakers}
                            setSelectedSpeakers={this.setSelectedSpeakers.bind(this)} />
                        <TrackList tracks={this.props.datastore.fetchTracks}
                            setSelectedTracks={this.setSelectedTracks.bind(this)}
                            selectedTracks={this.state.selectedTracks} />
                        <TimeZoneList setTimeZone={this.props.setTimeZone} selectedTimeZone={this.props.selectedTimeZone} />
                    </div>
                    <div className="col-xs-12 col-md-9 col-lg-9 col-xl-9 col-xxl-10 talks-list">
                        <TalksList datastore={this.props.datastore} daySelected={this.state.daySelected}
                            searchQuery={this.state.searchQuery} filterSpeakers={this.state.selectedSpeakers}
                            filterTracks={this.state.selectedTracks}
                            setSelectedTracks={this.setSelectedTracks.bind(this)}
                            selectedTimeZone={this.props.selectedTimeZone}
                            favouriteTalks={this.props.favouriteTalks}
                            toggleFavourite={this.props.toggleFavourite}
                            showFavourites={this.props.showFavourites} />
                    </div>
                </div>
           </div>
        )
    }
}