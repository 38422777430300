import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Select from 'react-select';

// polyfill to make Typescript shut up about this.
declare namespace Intl {
    type Key = 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit';

    function supportedValuesOf(input: Key): string[];
    function DateTimeFormat(): any;
}

interface TimeZoneListProps {
    selectedTimeZone: {value: string, label: string},
    setTimeZone: Function,
}

export default class TimeZoneList extends React.PureComponent<TimeZoneListProps, {}> {
    public timezones: string[];

    constructor(props: TimeZoneListProps) {
        super(props);
        this.timezones = Intl.supportedValuesOf("timeZone");
    }

    render() {
        return (
            <div className="time-zone-list row fs-5">
                <div className="col-xs-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text"><FontAwesomeIcon icon={solid('clock')} /></span>
                        <Select className="form-control form-control-lg p-0 ps-3" classNamePrefix="select"
                            onChange={newValue => this.props.setTimeZone(newValue)} 
                            placeholder="Select time zone..."
                            defaultValue={{
                                label: this.props.selectedTimeZone.label.replaceAll('_', ' '),
                                value: this.props.selectedTimeZone.value
                            }}
                            options={this.timezones.map(tzname => {
                            return {label: tzname.replaceAll('_', ' '), value: tzname};
                        })} />
                    </div>
                </div>
            </div>
        );
    }
}