import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import { DataStoreInterface, Speaker, Talk } from '../../datastore/DataStoreInterface';

import HomeButton from '../HomeButton';
import SpeakerComponent from '../speaker/Speaker';
import TalkLabel from '../talk/TalkLabel';
import FavouriteIcon from '../FavouriteIcon';
import AddToCalendarIcon from '../talk/AddToCalendarIcon';

import './TalkDetail.css';

interface TalkDetailProps {
    datastore: DataStoreInterface,
    selectedTimeZone: {value: string, label: string},
    toggleFavourite: Function,
    favouriteTalks: number[],
}

export default function TalkDetail(props: TalkDetailProps) {
    const { talkId } = useParams();
    const [talk, setTalk] = useState({} as Talk);
    const [loading, setLoading] = useState(true);

    useEffect(() => {    
        let talkIdNum = 0;
        if (talkId) {
            talkIdNum = parseInt(talkId);
        }
        props.datastore.fetchTalk(talkIdNum).then(data => {
            if (data.length === 1) {
                setTalk(data[0]);
                setLoading(false);
            }
        });
    }, [props.datastore, talkId]);

    return (
        <div className="container mw-100">
            <HomeButton />
            {loading ? (
                <div className="row loader-container text-center align-items-center">
                    <div className='col-xs-12 col-md-12 text-center'>
                        <ClipLoader color={'#05ffcf'} loading={loading} size={150} />
                    </div>
                </div>
            ): (
                <div className="row talk-detail" key={talk.id}>
                    { talk.attributes.video_url &&
                        <div className="talk-video col-md-6 col-xs-12">
                            <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                                <iframe src={`${talk.attributes.video_url}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} title={talk.attributes.title}></iframe>
                            </div>
                        </div>
                    }
                    <div className={"talk-details col-xs-12 " + (talk.attributes.video_url ? 'col-md-6' : 'col-md-12')}>
                        <div className="row">
                            <div className="col-sm-10 col-md-10">
                                <TalkLabel talk={talk} />
                            </div>
                            <div className='col-sm-2 col-md-2 text-end'>
                                <AddToCalendarIcon talk={talk} />
                                <FavouriteIcon talk={talk} favourite={props.favouriteTalks.indexOf(talk.id) !== -1}
                                    toggleFavourite={props.toggleFavourite} />
                            </div>
                        </div>

                        <div className="row align-items-top">
                            <h1 className='col-sm-12 col-md-12 talk-title'><Link to={`/talk/${talk.id}`}>{talk.attributes.title}</Link></h1>
                        </div>

                        <div className="talk-times fs-6 mb-3">
                        <span className='date'>{new Date(talk.attributes.start).toLocaleDateString(undefined, {
                            year: 'numeric', month: 'long', day: 'numeric', timeZone: props.selectedTimeZone.value,
                        })}</span>
                        <span className="start-time">{new Date(talk.attributes.start).toLocaleTimeString(undefined, {
                            timeStyle: "short", timeZone: props.selectedTimeZone.value})}</span>{' - '}
                        <span className="end-time">{new Date(talk.attributes.end).toLocaleTimeString(undefined, {
                            timeStyle: "short", timeZone: props.selectedTimeZone.value})}</span>
                        <span className="timezone">Time zone: {props.selectedTimeZone.label}</span>
                        </div>

                        <p className='talk-description'>{talk.attributes.description}</p>
                        <div className='talk-speakers'>
                            <h2>Speakers</h2>
                            {talk.relationships?.speakers.map((speaker: Speaker) => (
                                <Link to={`/speaker/${speaker.id}`} key={speaker.id}>
                                    <SpeakerComponent speaker={speaker} />
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            )}
       </div>
    );
}