import * as React from 'react';
import { Speaker, Talk, Track } from '../../datastore/DataStoreInterface';
import { Link } from 'react-router-dom';

import SpeakerComponent from '../speaker/Speaker';
import TalkLabel from './TalkLabel';
import FavouriteIcon from '../FavouriteIcon';

import './Talk.css';
import AddToCalendarIcon from './AddToCalendarIcon';

interface TalkProps {
    talk: Talk;
    setSelectedTracks: Function,
    selectedTimeZone: {value: string, label: string},
    favourite: boolean,
    toggleFavourite: Function,
}

export default function TalkComponent(props: TalkProps) {
    return (
        <div className="card talk my-4" key={props.talk.id}>
            <img src="/acid.webp" alt={props.talk.attributes.title}
                    title={props.talk.attributes.title} className="card-img-top" />
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col-sm-10 col-md-8">
                        <TalkLabel talk={props.talk} />
                    </div>
                    <div className='col-sm-2 col-md-4 text-end'>
                        <AddToCalendarIcon talk={props.talk} />
                        <FavouriteIcon talk={props.talk} favourite={props.favourite} toggleFavourite={props.toggleFavourite} />
                    </div>
                </div>

                <div className="row align-items-top">
                    <h1 className='col-sm-12 col-md-12 talk-title'><Link to={`/talk/${props.talk.id}`}>{props.talk.attributes.title}</Link></h1>
                </div>

                <div className="talk-times fs-6 mb-3">
                    <span className='date'>{new Date(props.talk.attributes.start).toLocaleDateString(undefined, {
                            year: 'numeric', month: 'long', day: 'numeric', timeZone: props.selectedTimeZone.value,
                        })}</span>
                    <span className="start-time">{new Date(props.talk.attributes.start).toLocaleTimeString(undefined, {
                        timeStyle: "short", timeZone: props.selectedTimeZone.value})}</span>{' - '}
                    <span className="end-time">{new Date(props.talk.attributes.end).toLocaleTimeString(undefined, {
                        timeStyle: "short", timeZone: props.selectedTimeZone.value})}</span>
                    <span className="timezone">Time zone: {props.selectedTimeZone.label}</span>
                </div>

                <div className='talk-speakers'>
                    {props.talk.relationships?.speakers.map((speaker: Speaker) => (
                        <Link to={`/speaker/${speaker.id}`} key={speaker.id}>
                            <SpeakerComponent speaker={speaker} />
                        </Link>
                    ))}
                </div>
                <div className="talk-tracks">
                    <span>Tracks: </span>
                    {props.talk.relationships?.tracks.map((track: Track) => (
                        <span className="btn btn-light" onClick={value => props.setSelectedTracks([{
                            value: track.id, label: track.attributes.name}])} key={track.id}>{track.attributes.name}</span>
                    ))}
                </div>
            </div>
        </div>
    );
}