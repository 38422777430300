export function localStorageAvailable() {
    try {
        // if window.localStorage is not defined, return false right away
        if (window.localStorage) {
            var test = "__localStorageTest__";
    
            // try to use localStorage, and if it does not give any error, then it is available
            try {
                window.localStorage.setItem(test, test);
                window.localStorage.removeItem(test);
            } catch (ex) {
                return false;
            }
    
            return true;
        }
    
        return false;
    } catch (DOMException) {
        return false;
    }
};