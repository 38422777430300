import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createEvents } from 'ics';
import { DateArray, EventStatus } from 'ics';

import { DataStoreInterface, Talk } from '../datastore/DataStoreInterface';

import './ImportCalendar.css';

interface ImportCalendarProps {
    favouriteTalks: number[],
    datastore: DataStoreInterface,
}

function downloadICS(talkIds: number[], datastore : DataStoreInterface) {
    // Get all favourite talks from the datastore
    datastore.fetchFavouriteTalks(talkIds).then(talks => {
        // Create events
        let events = [];
        for (let talk of talks) {
            let startDate = new Date(talk.attributes.start);
            let endDate = new Date(talk.attributes.end);
            const event = {
                start: [startDate.getUTCFullYear(), startDate.getUTCMonth()+1, startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes()] as DateArray,
                startInputType: 'utc' as ('utc' | 'local' | undefined),
                end: [endDate.getUTCFullYear(), endDate.getUTCMonth()+1, endDate.getUTCDate(), endDate.getUTCHours(), endDate.getUTCMinutes()] as DateArray,
                endInputType: 'utc' as ('utc' | 'local' | undefined),
                title: talk.attributes.title,
                description: talk.attributes.description,
                location: ('WEDF2022 ' + talk.relationships?.stream.attributes.name) || 'WEDF2022',
                url: 'https://forum.worldethicaldata.org/',
                status: "CONFIRMED" as EventStatus,
                busyStatus: 'BUSY' as ('TENTATIVE' | 'BUSY' | 'FREE' | 'OOF' | undefined),
                organizer: { name: 'World Ethical Data Forum 2022', email: 'contact@worldethicaldata.org' },
            };
            events.push(event);
        }
    
        // Download ICS file
        createEvents(events, (err, val) => {
            if (err) {
                console.error(err);
                return;
            }
            const element = document.createElement('a');
            const file = new Blob([val], {type: 'text/calendar'});
            element.href = URL.createObjectURL(file);
            element.download = "events.ics";
            document.body.appendChild(element); // firefox needs this
            element.click();
            return;
        });
    }).catch(err => console.error(err));
}

export default function ShowFavourites(props: ImportCalendarProps) {
    if (!(props.favouriteTalks.length > 0)) {
        return null;
    }

    return (
        <div className="calendar-import row fs-5">
            <div className="col-xs-12">
                <div className="input-group mb-3">
                    <a href="#" onClick={() => downloadICS(props.favouriteTalks, props.datastore)}>
                        <div className="row align-items-center">
                            <div className="favourite-text col-xs-12 d-flex align-items-center">
                                <FontAwesomeIcon icon={solid('calendar-plus')} size="2x" className="calendar-icon me-4" />
                                Add favourites to calendar
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}