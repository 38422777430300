import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Select from 'react-select';

import { Speaker } from '../datastore/DataStoreInterface';

import './SpeakerList.css';

interface SpeakerListProps {
    speakers: () => Promise<Speaker[]>,
    setSelectedSpeakers: Function,
}

interface SpeakerListState {
    speakers: Speaker[];
}

export default class SpeakerList extends React.PureComponent<SpeakerListProps, SpeakerListState> {
    constructor(props: SpeakerListProps) {
        super(props);
        this.state = {speakers: []};
    }

    async componentDidMount() {
        this.setState({speakers: await this.props.speakers()});
    }

    render() {
        return (
            <div className="speaker-list row fs-5">
                <div className="col-xs-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text"><FontAwesomeIcon icon={solid('person-chalkboard')} /></span>
                        <Select className="form-control form-control-lg p-0 ps-3" classNamePrefix="select" isMulti={true} 
                            onChange={newValue => this.props.setSelectedSpeakers(newValue)} 
                            placeholder="Select speakers..."
                            options={this.state.speakers.map(speaker => {
                            return {label: speaker.attributes.name, value: speaker.id};
                        })} />
                    </div>
                </div>
            </div>
        );
    }
}