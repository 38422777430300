import { Speaker } from '../../datastore/DataStoreInterface';

import './Speaker.css';

interface SpeakerComponentProps {
    speaker: Speaker
}

export default function SpeakerComponent(props: SpeakerComponentProps) {
    return (
        <div className='speaker row align-items-center' key={props.speaker.id}>
        <div className='speaker-photo col-xs-2 col-md-2'>
            <img src={props.speaker.attributes.photo?.url} alt={props.speaker.attributes.photo?.alt} 
                 title={props.speaker.attributes.photo?.title} />
        </div>
        <div className='speaker-name col-xs-10 col-md-10'>
            {props.speaker.attributes.name}
        </div>
    </div>
    )
};
